<template>
  <div>
    <h1>Payment cancelled!</h1>
    <p>
      Oops, something went wrong during the payment. Please try again.
    </p>
  </div>
</template>


<script>

export default {
  name: "Cancelled",
  props: {
  },
  data: function() {
    return {
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style scoped>
</style>